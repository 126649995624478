export type AgentType = {
  lat: number
  lng: number
  name: string
  desc: string
  email: string
  tel: string
  address?: string
  website?: string
  img: string
  linkedin?: string
  facebook?: string
  instagram?: string
  googlemybusiness?: string
}

export interface FormatedAgentType extends AgentType {
  key: string
}

export const AGENTS_INFORMATIONS = [
  {
    lat: 48.856614,
    lng: 2.3522219,
    name: 'Marie Dupont',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'marie.dupont@gmail.com',
    tel: '06 12 34 56 78',
    address: '15 Rue de Rivoli, 75004 Paris',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/mariedupont/',
    facebook: 'https://www.facebook.com/mariedupont',
    instagram: 'https://instagram.com/mariedupont',
    googlemybusiness: '',
  },
  {
    lat: 43.296482,
    lng: 5.36978,
    name: 'Jean Martin',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'jean.martin@gmail.com',
    tel: '06 23 45 67 89',
    address: '28 Rue Saint-Ferréol, 13001 Marseille',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/jeanmartin/',
    facebook: 'https://www.facebook.com/jeanmartin',
    instagram: 'https://instagram.com/jeanmartin',
    googlemybusiness: '',
  },
  {
    lat: 45.764043,
    lng: 4.835659,
    name: 'Sophie Lemoine',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'sophie.lemoine@gmail.com',
    tel: '06 34 56 78 90',
    address: '12 Rue de la République, 69002 Lyon',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/sophielemoine/',
    facebook: 'https://www.facebook.com/sophielemoine',
    instagram: 'https://instagram.com/sophielemoine',
    googlemybusiness: '',
  },
  {
    lat: 44.837789,
    lng: -0.57918,
    name: 'Pierre Dubois',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'pierre.dubois@gmail.com',
    tel: '06 45 67 89 01',
    address: '22 Rue Sainte-Catherine, 33000 Bordeaux',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/pierredubois/',
    facebook: 'https://www.facebook.com/pierredubois',
    instagram: 'https://instagram.com/pierredubois',
    googlemybusiness: '',
  },
  {
    lat: 48.573405,
    lng: 7.752111,
    name: 'Julie Bernard',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'julie.bernard@gmail.com',
    tel: '06 56 78 90 12',
    address: '8 Rue des Grandes Arcades, 67000 Strasbourg',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/juliebernard/',
    facebook: 'https://www.facebook.com/juliebernard',
    instagram: 'https://instagram.com/juliebernard',
    googlemybusiness: '',
  },
  {
    lat: 50.62925,
    lng: 3.057256,
    name: 'Laurent Caron',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'laurent.caron@gmail.com',
    tel: '06 67 89 01 23',
    address: '19 Place Charles de Gaulle, 59000 Lille',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/laurentcaron/',
    facebook: 'https://www.facebook.com/laurentcaron',
    instagram: 'https://instagram.com/laurentcaron',
    googlemybusiness: '',
  },
  {
    lat: 43.610769,
    lng: 3.876716,
    name: 'Nathalie Perrin',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'nathalie.perrin@gmail.com',
    tel: '06 78 90 12 34',
    address: '24 Rue Foch, 34000 Montpellier',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/nathalieperrin/',
    facebook: 'https://www.facebook.com/nathalieperrin',
    instagram: 'https://instagram.com/nathalieperrin',
    googlemybusiness: '',
  },
  {
    lat: 45.18756,
    lng: 5.735781,
    name: 'Antoine Leclerc',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'antoine.leclerc@gmail.com',
    tel: '06 89 01 23 45',
    address: '30 Rue de Bonne, 38000 Grenoble',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/antoineleclerc/',
    facebook: 'https://www.facebook.com/antoineleclerc',
    instagram: 'https://instagram.com/antoineleclerc',
    googlemybusiness: '',
  },
  {
    lat: 47.218371,
    lng: -1.553621,
    name: 'Camille Roche',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'camille.roche@gmail.com',
    tel: '06 90 12 34 56',
    address: '14 Rue de la Paix, 44000 Nantes',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/camilleroche/',
    facebook: 'https://www.facebook.com/camilleroche',
    instagram: 'https://instagram.com/camilleroche',
    googlemybusiness: '',
  },
  {
    lat: 49.443232,
    lng: 1.099971,
    name: 'Thomas Garnier',
    desc: 'Experte en crédits, regroupement de crédit, financement pro.',
    email: 'thomas.garnier@gmail.com',
    tel: '06 01 23 45 67',
    address: '26 Rue du Gros-Horloge, 76000 Rouen',
    website: 'www.lemonde.fr',
    img: 'v1727769495/team/Maxence_Gougeon.jpg',
    linkedin: 'https://www.linkedin.com/in/thomasgarnier/',
    facebook: 'https://www.facebook.com/thomasgarnier',
    instagram: 'https://instagram.com/thomasgarnier',
    googlemybusiness: '',
  },
]
