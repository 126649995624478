import { GOOGLE_AGENTS_MAP_ID, GOOGLE_API_KEY } from '@pretto/website/config'
import { RowPrettoGalaxie } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/components/RowPrettoGalaxie'

type RowPrettoGalaxieContainerProps = {
  buttonLabel?: string
  buttonUrl?: string
  isButtonDisplayed: boolean
  isColored: boolean
  isFirstRowWithPromises: boolean
  suptitle?: string
  text: string
  title: string
}

export const RowPrettoGalaxieContainer = (props: RowPrettoGalaxieContainerProps) => (
  <RowPrettoGalaxie googleApiKey={GOOGLE_API_KEY} googleAgentsMapId={GOOGLE_AGENTS_MAP_ID} {...props} />
)
