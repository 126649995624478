/* eslint-disable */
import { InfoWindowContent } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/components/AgentsMap/InfoWindowContent/InfoWindowContent'
import { type FormatedAgentType } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer'
import { InfoWindow, useMap } from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { AgentMarker } from './AgentMarker'

export type ClusteredAgentMarkersProps = {
  agents: FormatedAgentType[]
}

export const ClusteredAgentMarkers = ({ agents }: ClusteredAgentMarkersProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
  const [selectedAgentKey, setSelectedAgentKey] = useState<string | null>(null)

  const selectedAgent = useMemo(
    () => (agents && selectedAgentKey ? agents.find(t => t.key === selectedAgentKey)! : null),
    [agents, selectedAgentKey]
  )

  const map = useMap()
  const clusterer = useMemo(() => {
    if (!map) return null

    return new MarkerClusterer({ map })
  }, [map])

  useEffect(() => {
    if (!clusterer) return

    clusterer.clearMarkers()
    clusterer.addMarkers(Object.values(markers))
  }, [clusterer, markers])

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers

      if (marker) {
        return { ...markers, [key]: marker }
      }
      const { [key]: _, ...newMarkers } = markers

      return newMarkers
    })
  }, [])

  const handleInfoWindowClose = useCallback(() => {
    setSelectedAgentKey(null)
  }, [])

  const handleMarkerClick = useCallback((agent: FormatedAgentType) => {
    setSelectedAgentKey(agent.key)
  }, [])

  return (
    <>
      {agents.map(agent => (
        <AgentMarker key={agent.key} agent={agent} onClick={handleMarkerClick} setMarkerRef={setMarkerRef} />
      ))}

      {selectedAgentKey && selectedAgent && (
        <InfoWindow anchor={markers[selectedAgentKey]} onCloseClick={handleInfoWindowClose}>
          <InfoWindowContent agent={selectedAgent} />
        </InfoWindow>
      )}
    </>
  )
}
