import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import RichContent from '@pretto/bricks/website/about/components/RichContent'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { SecondaryButton } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'

import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'
import { AgentsMap } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/components/AgentsMap/AgentsMap'

import styled from 'styled-components'

export const ROW_PRETTO_GALAXIE_BG_COLORS = {
  light: 'white',
  colored: 'neutral4',
}

export const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ isColored, theme }) =>
    theme.colors[isColored ? ROW_PRETTO_GALAXIE_BG_COLORS.colored : ROW_PRETTO_GALAXIE_BG_COLORS.light]};
`

export const TextSection = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 4])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 3])};
  }
`
export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(2)};
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(3)};
`

export const Description = styled(RichContent)`
  margin-bottom: ${g(3)};
`

export const Image = styled(BaseImage)`
  display: block;
`

export const Button = styled(SecondaryButton).attrs({ scheme: 'black' })`
  margin-top: ${g(4)};
  margin-right: auto;
`

export const MapSection = styled.div`
  ${column([2, 4])};
  margin-top: ${g(5)};
  height: 268px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    height: 412px;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 7])};
    margin-top: unset;
    height: 460px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([7, 6])};
    height: 480px;
  }
`

export const Map = styled(AgentsMap)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`
