import { useBreakpoint } from '@pretto/bricks/assets/utility'

import {
  type AgentType,
  type FormatedAgentType,
} from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import { APIProvider, Map } from '@vis.gl/react-google-maps'

import { ClusteredAgentMarkers } from './ClusteredAgentMarkers'

const DEFAULT_CENTER = { lat: 47, lng: 2 }

type AgentsMapProps = {
  agents: AgentType[]
  googleApiKey: string
  googleAgentsMapId: string
}

const MAP_CONTROLERS = {
  mobile: {
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  },
  desktop: {
    zoomControl: true,
    mapTypeControl: true,
    streetViewControl: true,
  },
}

export const AgentsMap = ({ agents, googleApiKey, googleAgentsMapId }: AgentsMapProps) => {
  const { breakpoint } = useBreakpoint()
  const isMobile = ['mobileS', 'mobileM', 'mobileL', 'tablet'].includes(breakpoint || '')
  const mapControlers = isMobile ? MAP_CONTROLERS.mobile : MAP_CONTROLERS.desktop

  const formatedAgents = agents.map((agent, index) => ({
    ...agent,
    website: agent.website?.startsWith('www') ? `https://${agent.website}` : agent.website,
    key: agent.name + index,
  })) as FormatedAgentType[]

  return (
    <APIProvider apiKey={googleApiKey}>
      <Map
        mapId={googleAgentsMapId}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={5}
        gestureHandling={'greedy'}
        {...mapControlers}
      >
        <ClusteredAgentMarkers agents={formatedAgents} />
      </Map>
    </APIProvider>
  )
}
