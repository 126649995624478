import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { FacebookSmall, InstagramSmall, LinkedinSmall } from '@pretto/picto'
import { FormatedAgentType } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import styled from 'styled-components'

type AgentMapProps = {
  agent: FormatedAgentType
}

export const InfoWindowContent = ({ agent, ...props }: AgentMapProps) => (
  <InfoWindowContentContainer {...props}>
    <Name>{agent.name}</Name>

    <Description>{agent.desc}</Description>
    <Email href={`mailto:${agent.email}`}>{agent.email}</Email>

    <BottomSection>
      <Photo path={agent.img}></Photo>

      <ContactSection>
        {agent.tel && <Number href={`tel:${agent.tel}`}>{agent.tel}</Number>}
        {agent.address && <Address>{agent.address}</Address>}
        {agent.website && (
          <Website href={agent.website} target="_blank">
            {agent.website}
          </Website>
        )}

        <SocialSection>
          {agent.facebook && (
            <PictoContainer href={agent.facebook}>
              <SocialPicto as={FacebookSmall} />
            </PictoContainer>
          )}
          {agent.linkedin && (
            <PictoContainer href={agent.linkedin}>
              <SocialPicto as={LinkedinSmall} />
            </PictoContainer>
          )}
          {agent.instagram && (
            <PictoContainer href={agent.instagram}>
              <SocialPicto as={InstagramSmall} />
            </PictoContainer>
          )}
        </SocialSection>
      </ContactSection>
    </BottomSection>
  </InfoWindowContentContainer>
)

const InfoWindowContentContainer = styled.div`
  background-color: white;
  width: ${g(45)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    right: unset;
  }
`

const Name = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1, -4)};
`

const Description = styled.div`
  ${({ theme }) => theme.typos.body4};
`

const Email = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typos.body4Underline};
  cursor: pointer;
`

const BottomSection = styled.div`
  display: flex;
  margin-top: ${g(2)};
  gap: ${g(2)};
`

const Photo = styled(BaseImage).attrs({ options: { height: '120', width: '96', crop: 'auto' } })`
  border-radius: ${g(1)};
`

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

const Number = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typos.body4Bold};
  cursor: pointer;
  margin-right: auto;
`

const Address = styled.div`
  ${({ theme }) => theme.typos.caption};
`

const Website = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typos.captionUnderline};
  cursor: pointer;
  margin-right: auto;
`

const SocialSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${g(1, 4)};
`

const PictoContainer = styled.a`
  text-decoration: none;
  cursor: pointer;
`

const SocialPicto = styled.div`
  height: ${g(3)};
  width: ${g(3)};
`
