import { FormatedAgentType } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import type { Marker } from '@googlemaps/markerclusterer'
import { AdvancedMarker } from '@vis.gl/react-google-maps'
import { useCallback } from 'react'

export type AgentMarkerProps = {
  agent: FormatedAgentType
  onClick: (agent: FormatedAgentType) => void
  setMarkerRef: (marker: Marker | null, key: string) => void
}

export const AgentMarker = ({ agent, onClick, setMarkerRef }: AgentMarkerProps) => {
  const handleClick = useCallback(() => onClick(agent), [onClick, agent])

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef(marker, agent.key),
    [setMarkerRef, agent.key]
  )

  return <AdvancedMarker position={{ lat: agent.lat, lng: agent.lng }} ref={ref} onClick={handleClick} />
}
